<template>
  <div class="complex-error-modal">
    <v-icon x-large color="error" class="icon">mdi-alert</v-icon>
    <div class="content">
      <h2 class="header h-typo-modal-h3">{{ title }}</h2>
      <p class="ma-0">{{ text }}</p>
      <div class="btn-container" v-if="!hideBtn">
        <h-btn @click="$emit('ok')">{{ btnText }}</h-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'complex-error',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    hideBtn: {
      type: Boolean,
      default: () => false,
    },
    btnText: {
      type: String,
      default: 'ok',
    },
  },
};
</script>

<style lang="scss" scoped>
.complex-error-modal {
  display: flex;
  align-items: flex-start;
}

.icon {
  margin-top: 4px;
}

.content {
  padding-left: 22px;
}

.header {
  color: var(--color-error);
  margin-bottom: var(--gap);
}

.btn-container {
  margin-top: var(--gap-xlarge);
  text-align: right;
}
</style>
