<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" ref="dialog" max-width="600" :title="$t('components.instant_card_all_set_modal.title')" :closing-x="false">
    <div class="all-set-modal-content">
      <span class="action-number">1</span>
      <div>
        <p class="h-typo-modal-h4 font-weight-regular section-paragraph">{{ $t('components.instant_card_all_set_modal.download_title') }}</p>

        <ul class="advantage-list">
          <li class="font-weight-bold">{{ $t('components.instant_card_all_set_modal.download_incentive1') }}</li>
          <li>{{ $t('components.instant_card_all_set_modal.download_incentive2') }}</li>
          <li>{{ $t('components.instant_card_all_set_modal.download_incentive3') }}</li>
        </ul>

        <div v-if="mobileDisplay" class="mobile-content">
          <app-store-badges />

          <p>{{ $t('components.instant_card_all_set_modal.download_other') }}</p>
        </div>

        <div v-else class="desktop-content">
          <p class="section-paragraph">{{ $t('components.instant_card_all_set_modal.scan_qr') }}</p>

          <img class="store-qr-code" src="@/assets/QR_code.png" :alt="$t('components.instant_card_all_set_modal.qr_code')" />

          <p class="section-paragraph" style="margin-bottom: 0">{{ $t('components.instant_card_all_set_modal.sms_link') }}</p>

          <hello-form-error class="pa-0" />
          <h-form ref="askForSms" class="sms-form" v-model="valid" @submit.prevent="sendSms">
            <h-text-field
              class="sms-form-input"
              v-maska="'+1 (###) ###-####'"
              v-model="mobileNumber"
              :label="$t('components.instant_card_all_set_modal.input_label')"
              :hint="$t('components.instant_card_all_set_modal.input_hint')"
              :rules="mobileNumberRules"
              persistent-hint
              autocomplete="mobile tel"
            />

            <h-btn large type="submit" class="sms-form-btn" :disabled="!valid" :loading="working" outlined>
              <span v-if="!mobileSend">{{ $t('components.instant_card_all_set_modal.btn_text') }}</span>
              <v-icon v-else>mdi-check</v-icon>
            </h-btn>
          </h-form>

          <p class="section-paragraph">{{ $t('components.instant_card_all_set_modal.download_other') }}</p>

          <img class="store-img" src="@/assets/apple_logo.png" :alt="$t('components.instant_card_all_set_modal.available_apple')" />
          <img class="store-img" src="@/assets/google_play_logo.png" :alt="$t('components.instant_card_all_set_modal.available_google')" />
        </div>
      </div>

      <span class="action-number">2</span>
      <div>
        <p class="h-typo-modal-h4 font-weight-regular">{{ $t('components.instant_card_all_set_modal.reloadable') }}</p>
        <div class="bottom-btn">
          <h-btn @click="goNext" large min-width="225">{{ $t('components.instant_card_all_set_modal.all_done') }}</h-btn>
        </div>
      </div>
    </div>
  </h-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateMobileNumber } from '@/composables/validations';
import _replace from 'lodash/replace';
import safeExecute from '@/composables/safe-execute';
import _get from 'lodash/get';
import { recaptcha } from '@/mixins';
import AppStoreBadges from '@/components/utilities/AppStoreBadges';
import HelloFormError from '../system-store/errors/HelloFormError.vue';

export default {
  name: 'instant-card-all-set-modal',
  components: { AppStoreBadges, HelloFormError },
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);

    return {
      executeForm,
      working,
      validateMobileNumber,
    };
  },
  mixins: [recaptcha],
  data() {
    return {
      valid: false,
      mobileNumber: undefined,
      mobileSend: false,
      mobileNumberRules: [this.validateMobileNumber()],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    mobileDisplay() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    goNext() {
      this.$refs.dialog.close();
      this.$emit('next');
    },
    async sendSms() {
      if (this.$refs['askForSms'].validate()) {
        if (!this.mobileSend) {
          this.executeForm(
            {
              action: 'instantCard/sendSmsActivatedInstantCard',
              success: () => {
                this.mobileSend = true;
              },
            },
            {
              language: this.currentLocale,
              mobile_number: _replace(this.mobileNumber, /[()+]/g, '').replace(/ /g, '-'),
              recaptcha_token: await this.recaptcha('sendMobileSms'),
            },
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.advantage-list {
  margin-top: 0px;
  margin-bottom: 0px;
  li {
    line-height: 1;
  }
  @media ($sm-min) {
    margin-bottom: var(--gap);
  }
}
.all-set-modal-content {
  --action-number-size: 24px;
  padding-right: calc(var(--action-number-size) + 8px);

  @media ($sm-min) {
    --action-number-size: 28px;
  }

  display: grid;
  grid-template-columns: calc(var(--action-number-size) + 8px) auto;
  grid-template-rows: auto auto auto;
}

.action-number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--action-number-size);
  width: var(--action-number-size);
  border-radius: 50%;
  background-color: var(--color-primary);
  color: var(--color-ultra-light);
  font-size: 16px;
}

.bottom-btn {
  margin-top: var(--gap-xlarge);
  text-align: center;
}

// Mobile styles
.mobile-content {
  margin: 0 0 var(--gap-xxlarge) 0;
}

.store-link-img {
  display: block;
  width: 160px;
}

// Desktop styles
.desktop-content {
  color: var(--color-primary);
}

.section-paragraph {
  margin-bottom: var(--gap-xxsmall);
}

.store-img {
  margin: 0 var(--gap) var(--gap) 0;
}

.store-qr-code {
  text-align: left;
  display: block;
  margin: 0 auto var(--gap) 0;
  width: 100px;
}

.sms-form {
  display: flex;
  margin-bottom: var(--gap);
}

.sms-form-input {
  flex-grow: 1;
  margin-right: var(--gap);
}

.sms-form-btn {
  margin-top: 32px;
}
</style>
