<template>
  <h-fixed-footer-dialog v-bind="$attrs" v-on="$listeners" ref="dialog" :closing-x="false" max-width="600" :title="$t('components.find_card_step_form.found_card_modal.title')">
    <p class="mb-6">{{ $t('components.find_card_step_form.found_card_modal.text') }}</p>
    <img class="offer-image" :src="horizontalLogoImageHelper(instantCardOffer.partner_image[currentLocale])" alt="" />

    <offer-balance-card :image-src="instantCardOffer.images[currentLocale][0]" :balance="instantCardOffer.payment_amount + instantCardOffer.bonus_amount" :stay-small="true" />

    <div class="expend-container">
      <h3 class="expend-title">{{ instantCardOffer.label[currentLocale] }}</h3>
      <p class="expend-text">{{ instantCardOffer.description[currentLocale] }}</p>
    </div>

    <p v-for="(expirationText, index) of successModalExpirationText" :key="index">{{ expirationText }}</p>

    <p>{{ $t('components.find_card_step_form.found_card_modal.reassuring_text') }}</p>

    <template #action-footer>
      <h-btn @click="$refs.dialog.close()">{{ $t('components.find_card_step_form.found_card_modal.ok') }}</h-btn>
    </template>
  </h-fixed-footer-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { horizontalLogoImageHelper } from '@/composables/image-helper';
import OfferBalanceCard from '@/components/instant-card-activation/OfferBalanceCard';

export default {
  name: 'find-card-success-modal',
  components: { OfferBalanceCard },
  setup() {
    return {
      horizontalLogoImageHelper,
    };
  },
  computed: {
    ...mapGetters('instantCard', ['instantCardOffer']),
    ...mapGetters('ui', ['currentLocale']),
    infoModalDetailText() {
      return this.infoModalExpandedDescription ? this.$t('components.find_card_step_form.found_card_modal.less_detail') : this.$t('components.find_card_step_form.found_card_modal.more_detail');
    },
    infoModalDetailIcon() {
      return this.infoModalExpandedDescription ? 'mdi-chevron-up' : 'mdi-chevron-down';
    },
    successModalExpirationText() {
      const passedArgs = {
        bonus_amount: this.$options.filters.currencySymbol(this.$options.filters.currency(this.instantCardOffer.bonus_amount)),
        payment_amount: this.$options.filters.currencySymbol(this.$options.filters.currency(this.instantCardOffer.payment_amount)),
        closure_date: this.$options.filters.formatTextDate(this.instantCardOffer.closure_date),
        bonus_expiration_date: this.$options.filters.formatTextDate(this.instantCardOffer.bonus_expiration_date),
      };

      let text = [];

      if (this.instantCardOffer.payment_amount > 0 && this.instantCardOffer.bonus_amount > 0) {
        if (this.instantCardOffer.closure_date) {
          text.push(this.$t('components.find_card_step_form.found_card_modal.expiration_mixed', passedArgs));
        }
      } else if (this.instantCardOffer.payment_amount > 0 && this.instantCardOffer.closure_date) {
        if (this.instantCardOffer.closure_date) {
          text.push(this.$t('components.find_card_step_form.found_card_modal.expiration_contribution', passedArgs));
        }
      } else if (this.instantCardOffer.bonus_amount > 0) {
        text.push(this.$t('components.find_card_step_form.found_card_modal.expiration_bonus', passedArgs));
      }

      if (this.instantCardOffer.payment_amount > 0 && this.instantCardOffer.dormancy_enabled) {
        text.push(
          this.$t('components.find_card_step_form.found_card_modal.dormancy', {
            amount: this.$options.filters.currency_full(this.instantCardOffer.dormancy_fee),
            delay: this.instantCardOffer.dormancy_last_activity_trigger_in_months,
          }),
        );
      }

      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-image {
  display: block;
  max-height: 60px;
  max-width: 150px;
  margin-bottom: var(--gap);

  @media ($sm-min) {
    max-height: 80px;
    max-width: 200px;
  }
}

.expend-container {
  margin: var(--gap) 0 var(--gap);
}

.expend-title {
  margin-bottom: var(--gap);
}

.expend-text {
  color: var(--color-text-secondary);
}
</style>
