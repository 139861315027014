<template>
  <div :class="['offer-balance-card', { 'no-promoter-logo': !promoterImageSrc }]">
    <div v-if="promoterImageSrc" class="promoter-round-logo">
      <img class="promoter-logo" :src="promoterImageSrc" alt="" />
    </div>

    <img class="offer-image" :src="showcaseSmall(imageSrc)" />
    <div class="offer-balance">
      <span :class="['offer-balance-label', { 'stay-small': staySmall }]">{{ $t('components.instant_card_activation_view.step_4.balance') }}</span>
      <span :class="['offer-balance-value', { 'stay-small': staySmall }]">{{ balance | currency | currencySymbol }}</span>
    </div>
  </div>
</template>
<script>
import { showcaseSmall } from '@/composables/image-helper';

export default {
  setup() {
    return {
      showcaseSmall,
    };
  },
  name: 'offer-balance-card',
  props: {
    imageSrc: {
      type: String,
      required: false,
    },
    balance: {
      type: Number,
      required: true,
    },
    staySmall: {
      type: Boolean,
      default: false,
    },
    promoterImageSrc: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.offer-balance-card {
  display: flex;
  margin: 0 auto;
  max-width: 610px;

  --max-promoter-logo: 80px;
  --max-promoter-logo-offset: calc(var(--max-promoter-logo) * 7 / -12);
  --max-promoter-logo-offset-positif: calc(var(--max-promoter-logo-offset) * -1);
  padding: var(--max-promoter-logo-offset-positif);
  padding-bottom: 0px;
  position: relative;

  @media ($sm-min) {
    --max-promoter-logo: 124px;
  }

  &.no-promoter-logo {
    padding: 0px;
  }
}

.offer-image {
  width: 54%;
}

.offer-balance {
  width: 46%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--gap);
  border: var(--border-thin) solid var(--color-border);

  @media ($sm-min) {
    flex-direction: row;

    justify-content: space-between;
  }
}

.offer-balance-label {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--color-text-secondary);

  @media ($sm-min) {
    font-size: 1.25rem;
  }

  @media ($md-min) {
    &:not(.stay-small) {
      font-size: 1.25rem;
    }
  }
}

.offer-balance-value {
  font-size: 1.5rem;
  font-weight: 700;

  @media ($sm-min) {
    font-size: 2rem;
  }

  @media ($md-min) {
    &:not(.stay-small) {
      font-size: 2.25rem;
    }
  }
}

.promoter-round-logo {
  background-color: var(--color-ultra-light);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border: var(--border-thin) solid var(--color-border);
  border-radius: 50%;
  height: var(--max-promoter-logo);
  width: var(--max-promoter-logo);
  top: 0px;
  left: 0px;
}

.promoter-logo {
  max-width: 80%;
  max-height: 80%;
}
</style>
