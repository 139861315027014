<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" ref="dialog" :closing-x="false" error icon="mdi-alert" :title="$t('components.activate_card_step_form.error_modal.title')">
    <p>{{ $t('components.activate_card_step_form.error_modal.text') }}</p>

    <template #action-footer>
      <h-btn @click="close">{{ $t('components.activate_card_step_form.error_modal.btnText') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
export default {
  name: 'activate-card-error-modal',
  methods: {
    close() {
      this.$refs.dialog.close();
    },
  },
};
</script>
