<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" ref="dialog" :closing-x="false" max-width="600" :title="$t('components.find_card_step_form.found_card_modal_without_voucher.title')">
    <p>{{ $t('components.find_card_step_form.found_card_modal_without_voucher.no_offer_info_text') }}</p>

    <template #action-footer>
      <h-btn @click="$refs.dialog.close()">{{ $t('components.find_card_step_form.found_card_modal.ok') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'find-card-success-modal-without-voucher',
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
