<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" ref="dialog" error :closing-x="false" icon="mdi-alert" :title="errorTitle">
    <slot name="text">
      <p>{{ errorText }}</p>
    </slot>

    <template #action-footer>
      <h-btn @click="$refs.dialog.close()">{{ $t('components.find_card_step_form.found_card_modal.ok') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
export default {
  name: 'find-card-error-modal',
  props: {
    errorTitle: {
      type: String,
      required: true,
    },
    errorText: {
      type: String,
    },
  },
};
</script>
