<template>
  <h-dialog v-bind="$attrs" v-on="$listeners" ref="dialog" success persistent :closing-x="false" :title="$t('components.activate_card_step_form.card_activated_modal.title')">
    <p>{{ $t('components.activate_card_step_form.card_activated_modal.pin_text') }}</p>
    <complex-error v-if="pinError" class="pin-error" :title="$t('components.activate_card_step_form.card_activated_modal.pin_error.title')" :text="$t('components.activate_card_step_form.card_activated_modal.pin_error.text')" hide-btn />

    <div v-if="!pinError" class="pin-success">
      <p class="pin-label h-typo-modal-h4">{{ $t('components.activate_card_step_form.card_activated_modal.pin_label') }}</p>
      <p class="pin-value h-heading-h1">{{ pin }}</p>
    </div>
    <p v-if="!pinError">{{ $t('components.activate_card_step_form.card_activated_modal.reassuring_text') }}</p>

    <template #action-footer>
      <h-btn @click="close">{{ $t('components.activate_card_step_form.card_activated_modal.btnText') }}</h-btn>
    </template>
  </h-dialog>
</template>

<script>
import ComplexError from './components/ComplexError.vue';

export default {
  name: 'activate-card-success-modal',
  components: { ComplexError },
  props: {
    pinError: {
      type: Boolean,
      required: true,
    },
    pin: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$refs.dialog.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.pin-error {
  padding: var(--gap);
  border: var(--border-thin) solid var(--color-error);
  border-radius: var(--border-radius);
}

.pin-success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--gap);
  border: var(--border-thin) solid var(--color-success);
  border-radius: var(--border-radius);
  margin-bottom: var(--gap);
}

.pin-label {
  margin: 0;
  color: var(--color-text-secondary);
}

.pin-value {
  margin: 0;
}
</style>
