<template>
  <h-fixed-footer-dialog v-bind="$attrs" v-on="$listeners" ref="dialog" :closing-x="false" max-width="600" class="find-multiple-offer-instant-card-success-modal" :title="$t('components.find_card_step_form.found_multi_offer_instant_card_modal.title')">
    <template #post-header>
      <div class="instant-card-details">
        {{ $t('components.find_card_step_form.found_multi_offer_instant_card_modal.text', { number_of_offers: instantCardActiveOffers.length, total_amount: activeOffersTotalAmount }) }}
      </div>
    </template>

    <div class="content">
      <div v-for="offer in instantCardActiveOffers" :key="`offer-id-${offer.id}`" class="offer-list-row">
        <img class="offer-image-row" :src="showcaseSmall(extractOfferImage(offer))" />
        <div class="offer-title-row h-label-2">{{ offer.label[currentLocale] }}</div>
        <div class="offer-price-row h-heading-h2">{{ offer.total_amount | currency | currencySymbol }}</div>
      </div>
    </div>

    <template #action-footer>
      <h-btn @click="$refs.dialog.close()">{{ $t('components.find_card_step_form.found_multi_offer_instant_card_modal.ok') }}</h-btn>
    </template>
  </h-fixed-footer-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { showcaseSmall } from '@/composables/image-helper';

import _get from 'lodash/get';

export default {
  name: 'find-multiple-offer-instant-card-success-modal',
  setup() {
    return {
      showcaseSmall,
    };
  },
  methods: {
    extractOfferImage(offer) {
      return extractLocaleImage(this.currentLocale) || extractEnglishImage() || extractAnyImage();

      function extractLocaleImage(locale) {
        return _get(offer, `images[${locale}][0]`);
      }

      function extractEnglishImage() {
        return _get(offer, `images.en[0]`);
      }
      function extractAnyImage() {
        const anyImages = Object.values(offer.images).find((item) => item && item[0]);

        if (!anyImages) {
          return null;
        }

        return anyImages[0];
      }
    },
  },
  computed: {
    ...mapGetters('instantCard', ['instantCardActiveOffers']),
    ...mapGetters('ui', ['currentLocale']),
    activeOffersTotalAmount() {
      let total_amount = this.instantCardActiveOffers.reduce((total_amount, offer) => {
        return total_amount + offer.payment_amount + offer.bonus_amount;
      }, 0);
      total_amount = this.$options.filters.currency(total_amount);
      return this.$options.filters.currencySymbol(total_amount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/utilities/mixin.scss';

.instant-card-details {
  margin: var(--gap) 0;
  margin-bottom: var(--gap-xxlarge);
  font-size: var(--font-size-text-1);
  @media ($sm-min) {
    margin-top: 0;
    margin-bottom: var(--gap);
  }
}

.content {
  & > * {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .offer-image-row {
    height: 37px;
    width: 73px;

    @media ($sm-min) {
      height: 66px;
      width: 132px;
    }
  }
  .offer-title-row {
    @include cut-after-x-line(2);
    flex-grow: 1;
    flex-shrink: 2;
  }

  .offer-price-row {
    text-align: right;
  }

  .offer-list-row {
    display: flex;
    padding: var(--gap-xsmall);
    gap: var(--gap);
    border: var(--border-thin) solid var(--color-border);
    align-items: center;
    min-height: calc(52px + var(--gap));
  }
}
</style>
